import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import {
	FaAngellist,
	FaFileImport,
	FaGoogle,
	FaInstagram,
	FaLanguage,
	FaListUl,
} from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { IoIosHelpBuoy, IoIosSpeedometer, IoMdPaper } from "react-icons/io";
import { MdBrush, MdOndemandVideo } from "react-icons/md";
import ReactPlayer from "react-player";
import Faq from "../components/faq";
import Layout from "../components/layout";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const FlexibleOfficeWebDesign = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			needHelpImage: wpMediaItem(title: { eq: "London Commercial Large" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Flexible Office Web Design" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			flexImage: wpMediaItem(title: { eq: "Flex MasterLogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ryanImage: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			yopImage: wpMediaItem(title: { eq: "Your Office People Master Logo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},

			{
				"@type": "ListItem",
				position: "2",
				name: "Flexible Office Web Design",
				item: {
					url: `${siteUrl}/flexible-office-web-design`,
					id: `${siteUrl}/flexible-office-web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/flexible-office-web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						page="Flexible Office Web Design"
						// form
						title={`Flexible Office \nWeb <span class="text-secondary">Design</span>`}
						description={
							<span>
								Purpose-built website platform for flexible office agencies
								aiming to attract more leads and customers.
							</span>
						}
						buttons={[
							<Button
								className="px-4 py-2 bg-white border-white w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Talk to an expert
							</Button>,
							// <Button
							// 	className="px-5 py-2 mt-4 border-white w-100 cta-btn w-md-auto fs-5 white-link-yellow"
							// 	variant="primary"
							// 	href="#price-section"
							// >
							// 	Our pricing
							// </Button>,
						]}
					/>
				</section>

				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6 g-4">
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
									Grow your flexible office agency with our proven website
									solution
								</h2>

								<p>
									RJM Digital can help your flexible office agency achieve more
									leads and customers with our web design solution tailored for
									your business. Make it easier for your customers to find their{" "}
									<strong>ideal flexible office space</strong> using our search
									features to help them choose the most suitable listings for
									their business needs to help{" "}
									<strong>increase productivity</strong> in your workforce.
								</p>
								{/* <p>Our web design solution will allow you to:</p>

                <ul>
                  <li>
                    Import data from a leading CRE distributor and present the
                    information how you want it to be shown.
                  </li>
                  <li>
                    Let your clients take control by using filters to search for
                    their perfect work environment.
                  </li>
                  <li>
                    Stand out from your competitors as your new website will
                    include advanced features such as an interactive map and a
                    function for your clients to create shortlists of their
                    favourite offices.
                  </li>
                  <li>
                    Digitalise processes such as confirming offers, viewing
                    times and building shortlists to streamline the sale.
                  </li>
                </ul>

                <p>
                  RJM Digital have over 5 years experience in creating
                  successful websites that achieve results. For your new
                  website, get in touch today!
                </p> */}
								<div className="text-center text-lg-start">
									<Button
										className="px-4 py-2 mt-4 w-100 cta-btn w-md-auto fs-5 white-link-yellow"
										variant="primary"
										href="#form"
									>
										Talk to an expert
									</Button>
								</div>
							</Col>
							<Col lg={5}>
								<ReactPlayer
									playsinline
									loop
									muted
									playing
									className="h-auto w-100"
									url="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/10/Flexible-Office-Web-Design.mp4"
								/>
								{/* <div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.needHelpImage.gatsbyImage}
										alt={data.needHelpImage.altText}
									/>
								</div> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center g-5 g-lg-7">
							<Col xs={6} lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.flexofficesolutions.co.uk/"
								>
									<GatsbyImage
										image={data.flexImage.gatsbyImage}
										alt={data.flexImage.altText}
										class="w-100"
									/>
								</a>
							</Col>
							{/* <Col xs={6} lg={4}>
								<GatsbyImage
									image={data.ryanImage.gatsbyImage}
									alt={data.ryanImage.altText}
									class="w-100"
								/>
							</Col> */}
							<Col xs={6} lg={4}>
								<div style={{ background: "rgb(27, 27, 26)" }} className="p-4">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.yourofficepeople.co.uk/"
									>
										<GatsbyImage
											image={data.yopImage.gatsbyImage}
											alt={data.yopImage.altText}
											class="w-100"
										/>
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(49deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-7"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-center text-white display-5 mb-lg-7">
									Key features of our Flexible Office websites
								</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaListUl className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">
									Browse Your Listings
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Allow your prospects to search through your listings and pick
									which properties they want to view.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaFileImport className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Import listings</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Import your listings easily from 3rd party data sources via a
									spreadsheet and publish directly to your website.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoMdPaper className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">
									Existing & Upcoming Flexible Offices
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Add your knowledge and expertise to existing and upcoming
									commercial property developments.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaLanguage className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Multiple languages</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Help build trust and customer engagement by using their
									preferred language on your website.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdBrush className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Bespoke Design</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We help to build a website that represents your brand and
									reflects who you are as an agency.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosSpeedometer className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Ultra-Fast</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We will ensure a brilliant user experience by loading each
									web page in less than 1 second.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaGoogle className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">
									Searching For Particular Offices and Workspaces
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Appear higher on Google when buyers are searching for
									particular developments or projects.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosHelpBuoy className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Fully Managed</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We will maintain, support and secure your website so you never
									need to worry.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaAngellist className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">
									Featured Offices And Work Spaces
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Highlight your featured projects, listings or developments to
									attract buyers attention.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiDocumentText className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Upload useful docs</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Increase the engagement on your website  by uploading useful
									documents such as floor plans to each listing.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdOndemandVideo className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Virtual Tours</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Upload virtual tours of each office you have to offer with
									their meeting rooms, breakout spaces and their open plan
									layouts.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaInstagram className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Sync your socials</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Sync your social media channels and showcase your content
									across different platforms.
								</p>
							</Col>
						</Row>
						<Row className="mt-5  d-flex justify-content-center">
							<Col className="text-center">
								<Button
									className="px-4 py-2 mt-4 bg-white border-white w-100 cta-btn w-md-auto fs-5 blue-link-yellow"
									variant="primary"
									href="#form"
								>
									Book a demo
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row id="price-section">
							<Col>
								<h2 className="pb-5 text-center text-primary display-5">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								btnColour="secondary"
								title="Essential"
								subText="Agencies looking for their first website"
								price="£95"
								monthly
								setupFee="£995"
								features={[
									"Templated design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully Managed Website{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"GatsbyJS frontend",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Listings Manager{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Add, edit and remove listings via our Listings
															management tool
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"WordPress Content Management System",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											MapBox Integration{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Involves a usage charge billed directly from
															MapBox
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Unlimited Images, Audio and File content",
									"Fast and Secure Web Hosting",
									"SSL Certificate",
									"Custom Forms",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								btnColour="dark-blue"
								middle
								title="Premium"
								subText={
									<span>
										Commercial Property Agencies looking for a complete listing
										portal
									</span>
								}
								price="£195"
								monthly
								setupFee="£1495"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Essential</span>
									</span>,
									"Bespoke design",
									"SEO advice and basic implementation",
									"Monthly Analytics Report",
									"Shortlist functionality",
									"Monthly testing and updates on new browser / OS updates",
									"Lead capture forms and e-mail notifications",
								]}
							/>
							<PricingCard
								btnColour="light-blue"
								title="Pro"
								subText="Agencies looking for an advanced solution"
								price="£245"
								monthly
								setupFee="£2995"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Premium</span>
									</span>,
									"Fully customisable listings engine",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Login section for gated content{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Maximum user limits apply
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Multilingual functionality{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Translator costs charged separately on ad-hoc
															basis
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"Social media feed sync",
									"Integration with your CRM",
									"Progressive Web App",
									"Advanced SEO advice and implementation",
									"Conversion Rate Optimisation recommendations each month",
								]}
							/>
						</Row>
					</Container>
				</section> */}

				<WhyChooseRJMSection
					experienced="You need a specialist team to bring you new leads that you demand from your website. At RJM Digital we have over 5 years experience in the industry and have delivered a proven platform for flexible office agencies."
					bespoke="Each and every one of our websites is different and unique to the individual customer it was made for. We strive to create websites that stand out from the crowd."
					provenResults="Over the years we have continually delivered results for our customers and our testimonials highlight this. Most marketing agencies say they can deliver but don’t have the evidence to back it up."
					reliable="Throughout your whole partnership with RJM Digital, we will always be by your side for any help or assistance needed."
					transparent="At every step of the way we will be open in everything that we do, even if things don’t go as planned. We will always be available for a call or meeting to answer any queries you may have."
					trustworthy="By keeping to our core values of being reliable, transparent and results-driven, we are proud to have built long-lasting relationships with 50+ customers from around the world."
				/>

				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your London estate agent web design
									project.
								</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>

				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2sae!4v1673980072743!5m2!1sen!2sae" />
				{/* <section className="my-6">
          <TeamSection />
        </section> */}
				<div id="faq">
					<Faq page="Real Estate Dubai" />
				</div>
				{/* <OSSection
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        /> */}
			</Layout>
		</>
	);
};

export default FlexibleOfficeWebDesign;
